<template>
    <div id="sidebar">
        <div class="sidebar-inner">

            <div id="sidebar-etc">

                <b-row >
                    <b-col sm="12">
                        <router-link class="nounderline" :to="{ name: 'property', params: { propertyId: bookingData.hotelId, propertyName: this.bookingData.hotelName }}">
                            <BtnBack :text="'Torna indietro'" />
                        </router-link>
                        <br>
                    </b-col>
                </b-row>

                <div>
                    <div class="sidebar-checkout-panel">

                        <!-- credito welfare/info utente -->
                        <div v-if="!this.mixinIsPublic()" class="panel-content top">
                            <img :src="avatarSrc" />
                            <div>
                                <p class="name">{{displayName}}</p>
                                <!--<p v-if="availability > 0" class="credit">Credito Welfare € {{availability}}</p>-->
                            </div>
                        </div>

                        <!-- Riepilogo dei costi -->
                        <div class="panel-content">
                            <h5 class="panel-title">Riepilogo dei costi</h5>
                            <div class="checkout-resume-block pb-0">
                                <div class="checkout-resume-row align-left mb-2">
                                  <b>{{bookingData.roomDescription}}</b>
                                </div>
                                <div class="checkout-resume-row align-left pl-3 mb-2">
                                  <div>
                                    {{bookingData.numberOfAdults}} <span v-if="bookingData.numberOfAdults > 1">adulti</span><span v-else>adulto</span>
                                    <span v-if="bookingData.childs.length">, {{bookingData.childs.length}} <span v-if="bookingData.childs.length > 1">bambini</span><span v-else>bambino</span>
                                    (<span v-for="(c,i) in bookingData.childs" v-bind:key="_uid+i">{{ c }}<span v-if="i<(bookingData.childs.length-1)">, </span></span> anni)</span>
                                  </div>
                                </div>
                                <div class="checkout-resume-row pl-3">
                                    <span>{{bookingData.nights}} <span v-if="bookingData.nights > 1">Notti</span><span v-else>Notte</span></span>
                                    <span>
                                        {{mixinRoundTwoDigits(bookingData.totals.exclusive)}} €
                                    </span>
                                </div>
                                <div class="checkout-resume-row pl-3">
                                    <span
                                        v-b-popover.hover.top="'Questo addebito include gli importi stimati che il fornitore dei servizi di viaggio (ad esempio hotel, società di autonoleggio) paga in forma di tasse e/o le tasse che paghiamo alle autorità fiscali sulla tua prenotazione (incluse, a titolo esemplificativo, le imposte sulle vendite, di soggiorno o sul valore aggiunto). Può anche includere eventuali importi a noi addebitati per le tariffe resort, i supplementi per le pulizie e altre spese e/o un costo che noi, il fornitore dell\'hotel e/o il sito su cui hai prenotato tratteniamo come parte del compenso per i nostri servizi, che varia in base a fattori quali la posizione, l\'importo e la modalità di prenotazione. Per maggiori informazioni, consulta i termini e le condizioni.'"
                                        id="info-taxes" class="cursor-pointer">Tasse ed oneri <SvgInfoCircle />
                                    </span>
                                    <span>
                                        {{mixinRoundTwoDigits(bookingData.totals.inclusive - bookingData.totals.exclusive)*100/100}} €
                                    </span>
                                </div>
                                <div class="checkout-resume-row pl-3">
                                    <span v-b-popover.hover.top="'Costi di gestione della tua prenotazione e per l\'assistenza multicanale e dedicata ' + site.brand + '.'" id="info-taxes" class="cursor-pointer">{{ site.brand }} service charge <SvgInfoCircle />
                                    </span>
                                    <span>
                                        {{mixinRoundTwoDigits(bookingData.live_price - bookingData.totals.inclusive)*100/100}} €
                                    </span>
                                </div>
                            </div>

                            <div class="checkout-resume-block pb-0">
                                <div class="checkout-resume-row">
                                    <strong>Totale per il viaggio</strong>
                                    <strong>{{mixinRoundTwoDigits(bookingData.live_price)}} €</strong>
                                </div>

                                <div class="d-flex justify-content-center">
                                    <small>Totalmente saldato al momento della prenotazione</small>
                                </div>
                            </div>



                            <div class="checkout-resume-block pb-0" v-if="isCheckout && fees != 0">
                                <strong>Costi obbligatori non inclusi</strong>
                                <div class="checkout-resume-row" v-if="bookingData.totals.mandatory_tax !== 0">
                                    <span v-b-popover.hover.top="'Il costo della tassa di soggiorno viene definito dall\'Amministrazione Comunale, deve essere pagato in struttura ed è soggetto a variazioni. Il costo che vedi sul nostro sito è stato inserito dalla struttura alberghiera e non possiamo garantire che sia stato inserito correttamente e che sia aggiornato. Se desideri informazioni definitive ti invitiamo a consultare il sito dell\'Amministrazione Comunale o a contattare la struttura alberghiera.'">
                                      Tassa di soggiorno
                                      <SvgInfoCircle />
                                    </span>
                                    <span>{{mixinRoundTwoDigits(bookingData.totals.mandatory_tax)}} €</span>
                                </div>
                                <div class="checkout-resume-row" v-if="bookingData.totals.mandatory_fee !== 0">
                                    <span>Spese di pulizia ed utenze</span>
                                    <span>{{mixinRoundTwoDigits(bookingData.totals.mandatory_fee)}} €</span>
                                </div>
                                <div class="checkout-resume-row" v-if="bookingData.totals.resort_fee !== 0">
                                    <span>Resort fee</span>
                                    <span>{{mixinRoundTwoDigits(bookingData.totals.resort_fee)}} €</span>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <small>Da pagare in struttura al check-in</small>
                                </div>
                            </div>

                            <div class="sidebarResumeBlock pb-0" v-else-if="isCheckout">

                              <strong>Costi obbligatori non inclusi</strong>
                              <div class="d-flex justify-content-center">
                                La struttura non ha comunicato altri costi obbligatori da pagare in loco. Controlla anche il capitolo Informazioni importanti che trovi in questa pagina oppure contattaci per maggiori informazioni.
                              </div>

                            </div>

                        </div>

                        <!-- dati del viaggiatore -->
                        <div class="panel-content">
                            <h5 class="panel-title" v-b-toggle.checkoutStatusUserData>
                                <span class="checkoutDynamicBlockTitle">
                                    <span class="stepNumber">1</span> Dati del viaggiatore
                                </span>
                                <SvgChevronDown class="chevron" />
                            </h5>
                            <b-collapse id="checkoutStatusUserData" v-model="checkoutStatusUserDataVisible" accordion="checkoutStatus">
                                <div class="checkout-resume-block">

                                    <!-- SIDEBAR REFACTOR: sub component della sidebar, in futuro saranno tutti così; potenzialmente anche il tasto Conferma di ciascun cassetto potrebbe essere gestito a livello di sub component -->
                                    <CheckoutPax
                                        inputSize="lg"
                                        v-if="sidebarBlocks.checkoutPax.initialize"
                                        v-on:sidebarBlockChanged="sidebarBlockChange"
                                    />

                                    <!-- SIDEBAR REFACTOR: sub component della sidebar, in futuro saranno tutti così; potenzialmente anche il tasto Conferma di ciascun cassetto potrebbe essere gestito a livello di sub component -->
                                    <CheckoutBillToPax
                                        inputSize="lg"
                                        v-if="sidebarBlocks.checkoutBillToPax.initialize"
                                        v-on:sidebarBlockChanged="sidebarBlockChange"
                                    />
                                    
                                    <div class="checkoutStatusButtons mt-4">
                                        <b-button :disabled="!getUserDataStatus()" variant="success" v-b-toggle.checkoutStatusPaymentMethods>Conferma</b-button>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>

                        <!-- Strumenti di pagamento -->
                        <div class="panel-content checkoutCodes">
                            <h5 class="panel-title" v-if="getUserDataStatus()" v-b-toggle.checkoutStatusPaymentMethods>
                                <span class="checkoutDynamicBlockTitle">
                                    <span class="stepNumber">2</span>
                                    Pagamento
                                </span>
                                <SvgChevronDown class="chevron" />
                            </h5>
                            <h5 class="panel-title" v-else>
                                <span class="checkoutDynamicBlockTitle">
                                    <span class="stepNumber">2</span>
                                    Pagamento
                                </span>
                            </h5>
                            <b-collapse id="checkoutStatusPaymentMethods" v-model="checkoutStatusPaymentMethodsVisible" accordion="checkoutStatus">
                                <div class="checkout-resume-block" ref="scalapay-strumenti-pagamento">
                                    <div role="tablist">

                                        <!-- Scalapay -->
                                        <div class="paymentPartnerBlock" v-if="useScalapay && mixinIsPublic()">
                                          <div class="icon">
                                            <img src="assets/pagamenti/scalapay.svg" />
                                          </div>
                                          <div v-if="scalapayable" class="name etcBlue">
                                            {{ scalapay.numberOfPayments }} rate da {{mixinRoundTwoDigits(bookingData.live_price/scalapay.numberOfPayments)}} € senza interessi
                                          </div>
                                          <div v-else class="name etcBlue">
                                            non è disponibile per questa prenotazione
                                          </div>
                                        </div>

                                        <div class="copy" v-if="scalapayable">
                                          Prenota subito e paga in 3 rate da {{mixinRoundTwoDigits(bookingData.live_price/scalapay.numberOfPayments)}} € senza interessi con Scalapay - powered by Stripe. Clicca su Prenota e verrai reindirizzato sul sito Scalapay per la registrazione e il pagamento della 1a rata. Disponibile con carte di credito, carte di debito e prepagate.
                                        </div>

                                        <br/><br/>

                                        <!--<span class="togglePayment black" v-if="scalapayable">
                                            <span class="d-flex align-items-center">
                                                <font-awesome-icon icon="money-bill-alt"/> Riepilogo pagamenti
                                            </span>
                                        </span>-->

                                        <!--<div class="checkout-resume-row discountCodes" v-if="scalapayable">
                                          <div class="dcResume">

                                            <div class="discountCodeRow" v-for="(rate,index) in scalapayPaymentsSummary" v-bind:key="'rat-'+index">
                                              <div class="copy">
                                                {{ rate.label }}
                                              </div>
                                              <div class="spacer"></div>
                                              {{ rate.amount }} €
                                            </div>


                                            <div class="discountCodeRow">
                                              <strong class="copy">
                                                Totale
                                              </strong>
                                              <div class="spacer"></div>
                                              <strong class="value">{{mixinRoundTwoDigits(bookingData.live_price)}} €</strong>
                                            </div>

                                            <div v-if="scalapayable" class="discountCodeRow">
                                              <div class="copy">
                                                <small>Clicca su Prenota! per effettuare il pagamento con Scalapay</small>
                                              </div>
                                            </div>

                                          </div>

                                        </div>-->

                                    </div>

                                    <b-form-group class="mb-0" v-if="!scalapayable">
                                      <div class="cannotUseEtcAlert">

                                            <p>
                                              Scalapay permette di rateizzare in {{scalapay.numberOfPayments}} rate senza interessi solo prenotazioni fino a {{scalapay.maxAmount}} € di valore. <a target="_blank" href="https://scalapay.zendesk.com/hc/it/sections/360005291314-Limiti-di-spesa-potere-d-acquisto">Scopri di più sul sito Scalapay.</a>
                                            </p>

                                            <br/><br/>
                                            <router-link :to="{name: 'checkout'}">
                                              <BtnBack text="Paga in altro modo" />
                                            </router-link>

                                            <br/>
                                            <router-link :to="{name: 'homepage'}">
                                              <BtnBack text="Torna alla Home" />
                                            </router-link>

                                      </div>
                                    </b-form-group>

                                    <b-form-group class="mb-0" id="checkout-form" v-else>
                                      <div class="privacy-box">
                                        <b-form-checkbox v-model="guestPrivacy" ref="checkoutPrivacy" required type="checkbox" name="checkbox" id="checkbox-privacy" />
                                        <label for="checkbox-privacy">
                                          <router-link :to="{name: 'terms'}" target="_blank" class="nounderline">
                                            <span>Ho preso visione delle condizioni d'uso e le accetto</span>
                                          </router-link>
                                        </label>
                                      </div>

                                      <br>

                                      <div class="privacy-box">
                                        <b-form-checkbox v-model="guestGdpr" ref="checkoutGdpr" required type="checkbox" name="checkbox" id="checkbox-gdpr" />
                                        <label for="checkbox-gdpr">
                                          <p>
                                            <a :href="site.urlPrivacy" target="_blank">Ho preso visione delle condizioni di trattamento dei dati e le accetto</a>
                                          </p>
                                        </label>
                                      </div>

                                      <div class="privacy-box" v-if="disclaimerCheckout !== undefined">
                                        <label for="checkbox-gdpr">
                                          <!--<p class="checkboxInfo" v-html="disclaimerCheckout"></p>-->
                                          <p v-html="disclaimerCheckout"></p>
                                        </label>
                                      </div>

                                      <div v-if="!loading">
                                        <br/>
                                        <b-button class="btn btn-block btn-success" :disabled="!canFinallyCheckout()" @click="checkoutConfirm">PRENOTA!</b-button>
                                      </div>
                                      <Spinner v-bind:msg="'Prenotazione in corso... non abbandonare o ricaricare la pagina!'" v-else />
                                    </b-form-group>



                                </div>
                            </b-collapse>
                        </div>

                    </div>
                    

                    <!--<Stripe v-on:stripe-ok="stripeOk" />-->

                    <!-- confirm only -->
                    <div v-if="this.$route.name == 'confirm'">
                        <a href="/" class="btn btn-block btn-secondary">Torna alla Home</a>
                        <!--
                        TODO rendere contestuale
                        <br>
                        <a href="#" class="btn btn-block btn-secondary">Torna a Easy Welfare</a>-->
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Api from '../../../api'
import CheckoutBillToPax from './checkout-bill-to-pax' /* SIDEBAR REFACTOR */
import CheckoutPax from './checkout-pax' /* SIDEBAR REFACTOR */
import SvgInfoCircle from './../../svg/info-circle'
import SvgChevronDown from './../../svg/chevron-down'
import Spinner from './../../atoms/spinner'
import BtnBack from './../../atoms/btn-back'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTrash, faExclamationTriangle, faMoneyBillAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
library.add(faMoneyBillAlt)
library.add(faTrash)
library.add(faExclamationTriangle)
library.add(faPlus)
Vue.component('font-awesome-icon', FontAwesomeIcon)

//import ResumePaymentEtc from './resume-payment-etc'


export default {
    name: 'sidebar-scalapay',
    components:
    {
        Spinner,
        //SvgTicketIcon,
        SvgInfoCircle,
        SvgChevronDown,
        BtnBack,
        CheckoutPax, /* SIDEBAR REFACTOR */
        CheckoutBillToPax, /* SIDEBAR REFACTOR */

    },
    props:
    {
        //bookingData: Object
    },
    computed: {
        availability: {
            get() {
              // du sidebar-dc availability viene usata per fare i conti
              // devo gestire il caso "-1" (che a fini di conteggio prezzo và ignorato)
              if(this.$store.state.integration && this.$store.state.integration.availability && this.$store.state.integration.availability > 0){
                return this.$store.state.integration.availability
              }else{
                return 0
              }

            },
            set(value) { this.mixinSendMutation('setAvailability',value) }
        },
        token: {
            get() { return this.$store.state.token },
            set(value) { this.mixinSendMutation('setToken',value) }
        },
        bookingData: {
            get() { return this.$store.state.bookingData },
            set(value) { this.mixinSendMutation('setBookingData',value) }
        },
        affiliateReferenceId: {
            get() { return this.$store.state.affiliateReferenceId },
            set(value) { this.mixinSendMutation('setAffiliateReferenceId',value) }
        },
        scalapayable: {
          get() {

            // ritorna true se la tariffa costa meno (o uguale) al massimo importo rateizzabile con Scalapay
            if(this.mixinRoundTwoDigits(this.bookingData.live_price) <= this.scalapay.maxAmount) return true;
            return false;

          },
          set() {},
        },
        scalapayPaymentsSummary: {
          get(){

            let summary = []

            let live_price = this.mixinRoundTwoDigits(this.bookingData.live_price)
            let part = this.mixinRoundTwoDigits(live_price / this.scalapay.numberOfPayments)

            // gestione del resto
            let calculatedTotal = this.mixinRoundTwoDigits(part * this.scalapay.numberOfPayments);
            let change = this.mixinRoundTwoDigits(this.bookingData.live_price - calculatedTotal)

            for(let i=1; i<=this.scalapay.numberOfPayments; i++){

              let label = 'Oggi'
              if(i>1) label = i + ' rata'

              summary.push({
                label: label,
                amount: part,
              })

            }


            // in genere Scalapay mette i resti in questo modo
            if(change >= 0){
              summary[1].amount = this.mixinRoundTwoDigits(summary[1].amount + change) // eventuale eccedenza sul secondo pagamento
            }else{
              summary[2].amount = this.mixinRoundTwoDigits(summary[2].amount + change) // eventuale resto sull'ultimo pagamento
            }


            // !!! scalapay potrebbe calcolare le rate in modo diverso
            // https://scalapay.zendesk.com/hc/it/sections/360005291314-Limiti-di-spesa-potere-d-acquisto

            return summary;

          },
          set(){},
        }
    },
    data() {        
        return {
            checkoutStatusUserDataVisible: false,
            checkoutStatusPaymentMethodsVisible: true,
            checkoutStatusConfirmVisible: false,
            checkoutStatusUserDataOk: false,
            checkoutStatusPaymentMethodsOk: false,
            //checkoutStatusConfirmOk: false,


            //ccCollapseOpen: false,
            dcCollapseOpen: false,

            //status: 'not_accepted',
            displayName: Vue.prototype.$config.integration.displayName,
            avatarSrc: Vue.prototype.$config.integration.avatarSrc,
            disclaimerCheckout: Vue.prototype.$config.guiSettings.disclaimerCheckout,
            site: Vue.prototype.$config.site,
            loading: false,
            guestGdpr: (process.env.VUE_APP_MODE == 'development')? true : false,
            guestPrivacy: (process.env.VUE_APP_MODE == 'development')? true : false,

            fees: 0,

            useScalapay: (Vue.prototype.$config.guiSettings.integrations.scalapay.enabled)? true : false,
            scalapay: Vue.prototype.$config.guiSettings.integrations.scalapay,

            /* SIDEBAR REFACTOR: oggetto che conterrà tutti i sottoblocchi della sidebar richiesti per il checkout (dati beneficiario, dc, etc, billToPax, ecc) */
            sidebarBlocks: {
                checkoutPax: {
                    initialize: true,       // per inizializzarlo o meno nel template; è il valore che viene controllato anche per prelevare i campi prima della conferma
                    status: false,          // tiene traccia della validazione riuscita o meno dei campi
                    fields: {}              // campi che vengono eventualmente inviati a bookingData
                },
                checkoutBillToPax: {
                    initialize: this.mixinCanBillToPax(),
                    status: false,
                    fields: {}
                }
            },
            isCheckout: this.$route.name.includes('checkout'), // 'checkout' | 'checkout-scalapay'
        }
    },
    beforeMount()
    {
        //window.console.log('before mount')
    },
    mounted()
    {

        if(!this.useScalapay){
          this.mixinGoTo('checkout', {})
          return
        }


        this.fees = this.bookingData.totals.mandatory_tax + this.bookingData.totals.mandatory_fee + this.bookingData.totals.resort_fee

        let _this = this
        setTimeout(function(){ _this.gotoRefName('scalapay-strumenti-pagamento'); window.console.log('tick') }, 50);



    },
    methods:
    {


        /* SIDEBAR REFACTOR: metodo che dovrà gestire la validazione del cassetto aperto della sidebar, momentaneamente targettizzato solo su 'billToPax', in futuro astratto */
        sidebarBlockChange(e){
            let _this = this
            _this.sidebarBlocks[e.name].fields = e.fields
            _this.sidebarBlocks[e.name].status = e.status
            this.getUserDataStatus()
        },

        checkoutConfirm()
        {
            this.loading = true


            this.bookingData.payments.welfare.amount = 0
            this.bookingData.payments.cc.amount = 0
            this.bookingData.payments.discountCodes = 0

            this.bookingData.payments.scalapay = {}
            this.bookingData.payments.scalapay.amount = this.bookingData.live_price

            let _this = this,
                parameters = {},
                bookingData = Object.assign({},_this.bookingData)
            
            parameters.bookingData = bookingData
            parameters.token = _this.token

            // TODO verificare
            parameters.guestEmail = _this.sidebarBlocks.checkoutPax.fields.guestEmail
            parameters.guestEmailConf = _this.sidebarBlocks.checkoutPax.fields.guestEmailConf
            parameters.guestPhone = _this.sidebarBlocks.checkoutPax.fields.guestPhone
            parameters.guestPrivacy = _this.guestPrivacy
            parameters.guestGdpr = _this.guestGdpr
            parameters.beneficiaryId = 0
            parameters.guestFirstName = _this.sidebarBlocks.checkoutPax.fields.guestFirstName
            parameters.guestLastName = _this.sidebarBlocks.checkoutPax.fields.guestLastName

            /* SIDEBAR REFACTOR: serie di if che popoleranno bookingData con i set di campi che possono o meno comparire a seconda di come è stata costruita la sidebar */
            if(_this.sidebarBlocks.checkoutBillToPax.initialize) //non controllo lo status perché se è false non dovrei essere arrivato alla conferma
            {
                parameters.guestAddress = _this.sidebarBlocks.checkoutBillToPax.fields.guestAddress
                parameters.guestCity = _this.sidebarBlocks.checkoutBillToPax.fields.guestCity
                parameters.guestPostalCode = _this.sidebarBlocks.checkoutBillToPax.fields.guestPostalCode
                parameters.guestProvince = _this.sidebarBlocks.checkoutBillToPax.fields.guestProvince
                parameters.guestFiscalCode = _this.sidebarBlocks.checkoutBillToPax.fields.guestFiscalCode
            }
            
            Api
                .checkoutLivebooking(parameters)
                .then((response) => {

                    if(response.data.errors && !response.data.ignoreNotificationModal) _this.loading = false

                    if(response.status == 300)
                    {
                        _this.mixinSendMutation('setAffiliateReferenceId',response.data.retryWithAffiliateReferenceId)
                        _this.bookingData.affiliateReferenceId = response.data.retryWithAffiliateReferenceId
                        return _this.checkoutConfirm()
                    }


                    //if(response.status == 200){}
                    // scalapay è un "pagamento asincrono" (ritorna 202|303 e viene gestito dalla modalNotifications)

                })
        },
        validEmail(email){
            return /\S+@\S+\.\S+/.test(email)
        },
        getUserDataStatus()
        {
            /* SIDEBAR REFACTOR */
            if(this.sidebarBlocks.checkoutPax.initialize)
            {
                if(this.sidebarBlocks.checkoutPax.status == false) return false
            }

            /* SIDEBAR REFACTOR */
            if(this.sidebarBlocks.checkoutBillToPax.initialize)
            {
                if(this.sidebarBlocks.checkoutBillToPax.status == false) return false
            }

            /*
            if (this.guestEmail.length < 4) return false
            if (this.guestEmail !== this.guestEmailConf) return false
            if (this.guestPhone.length < 4) return false  
            if (this.guestFirstName.length < 4) return false
            if (this.guestLastName.length < 4) return false
            if (!this.validEmail(this.guestEmail)) return false // regex, alla fine
            */
            return true
        },
        getPaymentMethodsStatus()
        {
            if (!this.getUserDataStatus()) return false
            return true
        },
        canFinallyCheckout()
        {
            if (!this.getUserDataStatus()) return false           // ridondante, per sicurezza
            if (!this.guestPrivacy) return false
            if (!this.guestGdpr) return false
            return true
        },

        gotoRefName(refName) {
          var element = this.$refs[refName];
          var top = element.offsetTop;

          window.scrollTo(0, top);

          if(process.env.VUE_APP_MODE != 'production') window.console.log('dajee')
        },

    },
}
</script>

<style lang="scss">
    
    
    
    

    /*discount codes*/
    .checkoutCodes //#checkoutStatusPaymentMethods
    {
        //margin-top:0.5rem !important;
        .checkout-resume-block .checkout-resume-row
        {
            justify-content:flex-start;
            margin-bottom:1.5rem;
        }
        .newDiscountCodeBox
        {
            display:flex;
            align-items:center;
            //min-width:100%;
            line-height: 100%;
            width: 60%;
            margin-left: 10px;
            .newDiscountCode
            {
                min-width:55%;
                max-width:55%;
                margin-right:2.5%;
                padding: 0.25rem 0.5rem;
                height: auto;
                border-color:#ddd !important;
            }

            .invalid
            {
                //border-color:magenta !important;
            }
            p {display:none;}
            .invalid ~ p {display:block;}
            .invalid ~ button {
                //display:none;
            }

            button
            {
            }
        }
        
        .icon
        {
            width:26px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            *
            {
                fill:$black;
            }
        }

        .discountCodes
        {
            .discountCode
            {
                display: flex;
                flex-direction:column;
                min-width:100%;
                background:rgba($white,0.2);
                //border:1px solid rgba($white, 0.25);
                padding:0.5rem 0;// 0.75rem;
                border-radius: $border-radius;

                .svgInfo
                {
                    margin-top:0;
                }

                .spacer,
                .value,
                .copyDots:after,
                .copyDots:before
                {
                    transition: $transition-fade;
                }

                .not-collapsed
                {
                    .spacer,
                    .value,
                    .copyDots:after,
                    .copyDots:before
                    {
                        opacity:0;
                    }
                }
            }
            .discountCodeTitle
            {
                display: flex;
                justify-content:space-between;
                align-items: center;
                font-size: 16px;
                font-weight: 500;
                margin-bottom:8px;
                cursor:pointer;
                .chevron
                {
                    //max-width: 14px;
                    * {
                        stroke:$white;
                    }
                }
            }
            .discountCodeRow
            {
                display:flex;
                margin-bottom:0.75rem;
                &:last-child
                {
                    margin-bottom:0.5rem;
                }
                .copy
                {
                    padding-left:26px;
                    display: flex;
                    align-items: center;
                    line-height: 1;   
                    position:relative;
                    $dot: 16px;
                    .svgInfo
                    {
                        width:14px !important;
                        margin-right:0.25rem !important;
                        padding-top:2px;
                    }

                    &.copyDots:before
                    {
                        content:"";
                        position:absolute;
                        width:$dot;
                        height:$dot;
                        border-width:2px;
                        border-style: solid;
                        border-radius: 50%;
                        left:2px;
                        top:50%;
                        transform:translateY(-50%);
                    }
                    &.copyDots:after
                    {
                        content:"";
                        position:absolute;
                        width:$dot/2;
                        height:$dot;
                        left:10px;
                        top:50%;
                        transform:translateY(-50%);
                        z-index: 1;
                        border-bottom-right-radius: $dot/2;
                        border-top-right-radius: $dot/2;
                    }
                }
                .spacer
                {
                    flex:1;
                    border-bottom:1px solid rgba($black,0.5);
                    margin:0 6px 3px 6px;
                }
                .value
                {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    line-height: 1;
                    width:60px;
                    text-align:center;
                }
            }
            .discountCodeRemove
            {
                padding-top:6px;
                padding-bottom:6px;
                display:flex;
                align-items: center;
                justify-content: flex-end;
                font-size: 80%;
                line-height: 1;
                cursor:pointer;
                transition:$transition-base;
                *
                {
                    transition:$transition-base;
                }
                &:hover
                {
                    color:$danger;
                    * {fill:$danger;}
                }
                svg
                {
                    margin-left:10px;
                }
            }
            .dcResume
            {
                display:flex;
                flex-direction:column;
                min-width:100%;
                margin-top:8px;
                .discountCodeRow
                {
                    //margin-top:8px;
                }
                svg
                {
                    margin-right:6px;
                    *
                    {fill:$black;}
                }
            }
        }

        .codeInfo
        {
            flex:1;
        }
        .codeData
        {
            display:flex;
            @include media-breakpoint-down(md)
            {
                flex-direction: column;
            }
        }

        .dcCode
        {
            display:inline-block;
            color:$white;
            padding:3px 8px;
            border-radius:4px;
            //margin-top:4px;
            //margin-bottom:4px;
        }

        .validCode
        {
            .dcCode
            {
                background:$success;
            }
            .resume *
            {
                color:$success;
            }
            *
            {
                //fill:$success;
            }    
        }

        .invalidCode
        {
            .dcCode
            {
                background:$danger;
            }
            .resume *
            {
                color:$danger;
            }
            *
            {
               //fill:$danger;
            }
        }
        
        .warningCode
        {
            .dcCode
            {
                background:$gray-600;
            }
            .resume *
            {
                color:$gray-600;
            }
            *
            {
               //fill:$danger;
            }
        }


        //dots
        .fullDot //usato per intero, quindi green per forza
        {
            .copyDots:before
            {
                background-color:$success;
                border-color:$success;
            }
            .copyDots:after
            {
                display:none;
            }
        }
        .halfDot //usato in parte, quindi green per forza
        {
            .copyDots:before
            {
                background-color:transparent;
                border-color:$success;
            }
            .copyDots:after
            {
                background-color:$success;
            }
        }
        .grayDot //valido ma non usato, grigio
        {
            .copyDots:before
            {
                background-color:transparent;
                border-color:$gray-600;
            }
            .copyDots:after
            {
                display:none;
            }
        }
        .redDot //non valido quindi non usato, rosso per forza
        {
            .copyDots:before
            {
                //background-color:transparent;
                //border-color:$danger;
                display:none;
            }
            .copyDots:after
            {
                display:none;
            }
        }
        
        .icons
        {
            .dcOk path {fill:$success;}
            .dcKo path {fill:$danger;}
        }
        .code
        {
            align-items:center;
            .infoDivisible
            {
                display:flex;
                align-items:center;
            }
        }
        .resume
        {
            padding-right:1.5rem;
            align-items:center;
        }
        .remove
        {
            cursor:pointer;
            width:18px;
            text-align:center;
            svg
            {
                //width:100%;
            }
        }

        .icons
        {
            width:18px;
            text-align:center;
            margin-right:1rem;
            svg
            {
                width:100%;
            }
        }
        .code
        {
            padding-right:1rem;
            flex:1;
        }
        .resume
        {
            display:flex;
            text-align:left;
            justify-content:space-between;
            flex:1;
        }
        .info
        {

        }
    }
    /*discount codes*/



    #sidebar-etc
    {
        input, select, .form-control
        {
            border:1px solid $smooth-black;
            margin-bottom:0;//$grid-gutter-width/2;
            font-weight:400;
            box-shadow: none;
        }

        .inputBlock
        {
            flex:1;
            display:flex;
            flex-direction:column;
        }

        .checkoutStatusButtons
        {
            display:flex;
            flex-direction:column;
            //margin:$grid-gutter-width/2 0;
            button:not(.btn-sm)
            {
                margin:0 auto;
                max-width:420px;
                width:100%;
                //max-width: calc(100% - #{$grid-gutter-width});
                &:not(:last-child)
                {
                    margin-bottom:12px;
                }
            }
        }

        button
        {
            //font-weight:600;
            //font-size: 20px;
            box-shadow:none;
        }

        .btn-sm
        {
            min-width:140px;
        }

        label
        {
            font-weight:400;
            margin-bottom:4px;
        }
        
        div[role=tablist]
        {
            display:block;
            width:100%;
        }

        .togglePayment
        {
            width:100%;
            display:flex;
            justify-content: space-between;
            align-items:center;
            text-align:left;
            @include customize-color("secondary");
            font-weight: $font-weight-normal;
            font-size: 16px;
            cursor:pointer;
            svg
            {
                margin-right:6px;
                margin-bottom:2px;
            }
            .chevron
            {
                width:22px;
                margin-right:30px;
                path
                {
                    @include customize-stroke("secondary");
                }
            }
            &.black
            {
                color:$black;
                svg path
                {
                    stroke:$black;
                    fill:$black;
                }                
            }
        }

        .sidebar-checkout-panel
        {
            p
            {
                margin-bottom:0;
                font-weight:normal;
            }

            .top
            {
                padding:$grid-gutter-width/2;
                display:flex;
                align-items: center;
                padding-bottom:$grid-gutter-width/2;
                img {
                    width:44px;
                    height:44px;
                    margin-right:$grid-gutter-width/2;
                }
                p
                {
                    font-size:19px;
                }
                .name
                {
                    color:$white;
                    font-size: 16px;
                }
                .credit
                {
                    @include customize-color("secondary");
                    font-size: 14px;
                }
            }
            .panel-content
            {
                padding:$grid-gutter-width/2;
                color:$smooth-black;
                font-size:14px;
                color:$black;
                font-size:15px;
            }
        }

        .panel-title
        {
            margin-bottom:0;
            &[aria-expanded]
            {
                cursor:pointer;
                text-align:left;
                display:flex;
                align-items:center;
                align-content:center;
                justify-content:space-between;
            }
            &[aria-expanded=true]
            {
                color:$white;
                svg path
                {
                    stroke:$white;
                }
            }
            //&+ .collapse,
            //&+ .collapsing,
            &+ *
            {
                margin-top:1.5rem;
            }
        }

        .checkoutDynamicBlockTitle
        {
            display:flex;
            align-items:center;
            text-align:left;
            font-size:16px;
            @include media-breakpoint-up(lg)
            {
                font-size:20px;
            }
            .stepNumber
            {
                width:30px;
                height:30px;
                border-radius:50%;
                background:rgba($black,0.095);
                text-align:center;
                font-size: 75%;//14px;
                margin-right:12px;
                display:flex;
                align-items:center;
                justify-content:center;
            }
        }

        .panel-content:not(:last-child)
        {
            border-bottom:1px solid $white;
        }

        .privacy-link
        {
            @include customize-color("secondary");
            text-decoration:underline;
            font-size: 13px;
        }

        .checkout-resume-block
        {
            font-size: 14px;
            font-weight:400;

            .checkout-resume-row
            {
                display:flex;
                justify-content:space-between;
                align-items: center;

                & > span {
                    margin-bottom: 10px;
                    
                    &:first-child {
                        flex-basis: 60%;
                    }
                    &:last-child {
                        flex-basis: 40%;
                        text-align: right;
                        font-weight: 500;
                    }
                }
            }

            .checkoutPax, .checkoutBillToPax {
                .checkout-resume-row {
                    margin-bottom: 15px;
                }
            }
            
            strong
            {
                font-size: 16px;
            }
            small
            {
                display:block;
                //margin-top:8px;
            }
            svg.svgInfo
            {
                width: 18px;
                margin-left: 3px;
                margin-right: 10px;
                margin-top: 0;
            }
            &:not(:last-child)
            {
                border-bottom:1px solid rgba($white,0.65);
                margin-bottom:0.75rem;
                padding-bottom:0.75rem;
                small
                {
                    margin-bottom:8px;
                }
            }
        }

        strong.danger
        {
            margin:0.5rem auto;
            font-size:14px;
        }

        .danger
        {
            color:$danger;
            flex:100%;
            width:100%;
            min-width:100%;
            font-size:14px;
            cursor:pointer;
            svg
            {
                margin-right:4px;
                *
                {
                    fill:$danger;
                }
            }
        }

        #accordion-cc
        {
            display:block;
            margin-top:1rem;
        }

        //#accordion-cc
        //{
            .resumePayment //&:not(.show) + .resumePayment
            {
                justify-content: space-between;
                .value
                {
                    //text-align:right;
                    white-space:nowrap;
                    width:auto;
                    min-width:80px;
                    flex:0;
                    text-align:center;
                }
            }
        //}

        .vouchersTable
        {
            display:flex;
            flex-direction: column;
            text-align:center;
            .vouchersBlock
            {
                border-bottom:1px solid $white;
                width:100%;
                min-width:100%;
                margin-bottom:4px;
                padding-bottom:4px;
            }
            .vouchersTableRow
            {
                padding:4px 0;
                width:100%;
                display:flex;
                &>div
                {
                    width:33%;
                    min-width:33%;
                    flex:33%;
                }
            }
            strong
            {
                font-size: 14px;
            }
            .voucherValue
            {
                //color:$white;
                font-weight:$font-weight-lighter;
            }
            .voucherCount
            {
                .setVoucherIcon
                {
                    cursor:pointer;
                }
                display:flex;
                span
                {
                    width:33%;
                    min-width:33%;
                    flex:33%;
                }
            }
            .voucherTotal
            {
                //color:$white;
                font-weight:$font-weight-lighter;
            }
        }

        .dcLower
        {
            text-align:right;
            .etcAutoSelect
            {
                display:flex;
                align-items:center;
                justify-content: flex-end;
                margin-bottom:0.5rem;
            }
            .custom-switch
            {
                margin-left:1rem;
                label
                {
                    width:70px;
                    height:35px;
                    font-size:11px;
                    margin-bottom:0;
                }
            }
        }

        .resumePayment
        {
            .label
            {
                text-align:left;
                padding-left:24px;
            }
        }
        
        #checkout-form
        {
            width:100%;
        }

      .privacy-box
      {
        display: flex;
        justify-content:center;
        align-items:center;
        min-width:100%;
        @include customize-color("cta");
        margin-top:1rem;
        input
        {
          //margin-top: 2px;
        }
        label
        {
          flex:1;
          margin-bottom:0;
        }
        /*.checkoutStatusButtons
        label[for=checkbox-privacy]
        {
            padding-left: 8px;
            line-height: 120%;
            margin-bottom:0;
        }
        */
      }

        @include media-breakpoint-up(xl)
        {
            .panel .top,
            .panel .bottom
            {
                padding:$grid-gutter-width/3*2;
            }
            .panel .top
            {
                .name
                {
                    font-size: 18px;
                }
                .credit
                {
                    font-size: 18px;
                }
            }
            .panel .panel-content
            {
                padding:$grid-gutter-width/3*2;
                font-size:16px;
            }
        }

        @include media-breakpoint-down(sm)
        {
            .sidebar-checkout-panel
            {
                //margin-left:$grid-gutter-width/2;
                //margin-right:$grid-gutter-width/2;
                width:auto;
            }
        }

        @include media-breakpoint-only(lg)
        {
            .sidebar-checkout-panel
            {
                margin-right:$grid-gutter-width/2;
            }
            .panel-title
            {
                font-size: 18px;
                margin-bottom:10px;
            }
            .checkout-resume-row span
            {
                font-size: 12px;
            }
            #checkout-form
            {
                label
                {
                    font-size: 14px;
                }
            }
        }
    }

    // orribile hack per mostrare la sidebar etc su mobile senza rifattorizzare tutto quanto
    @include media-breakpoint-down(sm)
    {
        #checkout
        {
            #main
            {
                display:flex;
                flex-direction: column;
                #content
                {
                    //order:2;
                }
                #sidebar
                {
                    display:block !important;
                    //order:1;
                }
                .sidebar-inner
                {
                    width:calc(100% - #{$grid-gutter-width});
                    margin:0 $grid-gutter-width/2;
                    margin-top:$grid-gutter-width/2;
                }
            }
        }
    }



    //restyle vadobay
    .sidebar-checkout-panel
    {
        //@include customize-background-color("primary");
        //@include theme-gradient-horizontal();
        background-color:$white;
        border-radius: $border-radius-lg;
        .checkoutDynamicBlockTitle,
        .panel-title
        {
            @include customize-color("tertiary");
        }
        .chevron path
        {
            @include customize-stroke("tertiary");
        }
        .panel-content:not(:last-child),
        .checkout-resume-block:not(:last-child)
        {
            border-bottom-color:rgba($smooth-black,0.25) !important;
        }
    }

    .payment-logos
    {
        display:flex;
        justify-content:center;
        align-items:center;
        flex-wrap:wrap;
        margin:1.25rem 0;
        img,svg
        {
            width:auto;
            max-height:20px;
            margin:12px 8px;
        }
        .mastercard
        {
            max-height:30px;
        }
        .visa
        {
            max-height:26px;
        }
        .american-express
        {
            max-height:26px;
        }
        .buono-sconto-vivamod
        {
            max-height:30px;
        }
        .edenred-logo
        {
            max-height:32px;
        }
        .promoshopping
        {
            max-height:24px;
        }
        .scalapay        
        {
            max-height:24px;
        }
    }

    .dc-logo
    {
        display: flex; 
        text-align: center;
        display: block; 
        
        .logo
        {
            max-width:50%;
            height: auto;
            margin-bottom: 15px;

            @include media-breakpoint-up(sm){
                max-width:30%;
            }

            @include media-breakpoint-up(md){
                max-width:20%;
            }

            @include media-breakpoint-up(lg){
                width:60%;
                max-widtH: none;
            }
        }
        .newDiscountCodeBox
        {
            //min-width:100%;
            width: 100%;
            
            input
            {
                min-width: calc(100% - 40px) !important;
                border-radius: 2px;
                border:1px solid #aaa !important;                
            }
        }
        .newDiscountCode
        {
            flex:1;
            //width: 40%;
            min-width:auto;
        }
        .addButton
        {
            width:40px !important;
            padding:0;
            margin:0;
            background:transparent !important;
            border:0;
            outline:none;
            box-shadow: 0;
            display:flex;
            justify-content:flex-end;
            svg
            {
                width:20px;
                height:auto;
                circle
                {
                    transition:$transition-base;
                }
            }
        }

        .ko
        {
            display:none;
        }
        .ok
        {
            display:block;
        }
        .invalid ~ .addButton {
            //display:none;
            circle
            {
                //fill:#999;
            }
            .ok
            {
                display:none;
            }
            .ko
            {
                display:block;
            }
        }

        small
        {
            font-size:70%;
        }

    }
</style>